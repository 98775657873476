import React, { Component } from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { Dashboard } from '../Admin/Dashboard';
import { Games } from '../Games/Games';
import { Panels } from '../Panels/Panels';
import Login from '../Profile/Login/Login';

import Sidebar from '../Common/Sidebar';
import { connect } from 'react-redux';
import { setLogin } from '../../Redux/actions';
import { getCurrentUser } from '../../API/Configs/APIUtils';
import { ACCESS_TOKEN } from '../../API/Constants';
import { AccessControlAPI } from '../../API/Endpoints/Backend/AccessControl/AccessControlAPI';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/map';
import { Roles } from '../Roles/Roles';
import { Tags } from '../Tags/Tags';
import { User } from '../Users/User';
import { Dev } from '../Devs/Dev';
import { AccessControl } from '../Access Controls/AccessControl';

import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Roots } from '../Root/Roots';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchBarTerm: "",
      // authenticated: false,
      // currentUser: null,
      loading: true
    }


    // this.handleSearhTerm = this.handleSearhTerm.bind(this);
    // this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    // this.handleLogout = this.handleLogout.bind(this);
  }


  // handleSearhTerm = search => {
  //   this.setState({
  //     searchBarTerm: search
  //   })
  //   console.log(this.state.searchBarTerm);
  // }

  loadCurrentlyLoggedInUser() {

    // AccessControlAPI.findAll()
    //   .then(res => {

    //   });


    getCurrentUser()
      .then(response => {

        console.log(response);
        if (response.roleId == 8 || response.roleId == 9) {
          const { LogMeIn, setUserAccessControls } = this.props;
          LogMeIn(response);

          //AccessControlIDs
          var userAccessNames = response.userAccessControlList.map(x => x.accessControlName);
          setUserAccessControls(userAccessNames);
          console.log("Admin Ha");
        }
        else {
          localStorage.removeItem(ACCESS_TOKEN);
          console.log("User Ha");
        }


        // response.


        // dispatch(setLogin(response));
        // this.props.history.push("/");
        //   this.setState({
        //     currentUser: response,
        //     authenticated: true,
        //     loading: false
        //   });
      });
  }

  // handleLogout() {
  //   localStorage.removeItem(ACCESS_TOKEN);
  //   this.setState({
  //     authenticated: false,
  //     currentUser: null
  //   });
  //   Alert.success("You're safely logged out!");
  // }

  componentDidMount() {
    this.loadCurrentlyLoggedInUser();
    // if (!this.props.authenticated) {
    //   <Redirect
    //     to={{
    //       pathname: "/login",
    //       state: { from: this.props.location }
    //     }} />;
    // }



  }
  handleNavigation = () => {
    const { history } = this.props;
    history.push('/login');
  }

  render() {
    // if (this.state.loading) {
    //   return <LoadingIndicator />
    // }
    const { authenticated, currentUser } = this.props;
    // if (!authenticated) {
    //   this.props.history.push("/login");
    // }








    return (
      <div className="app">
        <div className="app-top-box">
          {/* <AppHeader searchTerm={this.state.searchBarTerm} onSearched={this.handleSearhTerm} authenticated={this.state.authenticated} currentUser={this.state.currentUser} onLogout={this.handleLogout} /> */}
        </div>
        <div className="app-body">
          <div className="dashboard d-flex">
            <div>
              {
                (authenticated) ?
                  <Sidebar /> : null
              }

            </div>
            <div style={{
              flex: "1 1 auto",

              display: "flex",
              flexFlow: "column",
              height: "100vh", overflowY: "scroll", background: "grey"
            }}>



              <Switch>

                <Route path="/login"
                  render={(props) => <Login />}></Route>

                {
                  (authenticated) ?
                    <>
                      <Route exact path="/" component={Dashboard} />

                      <Route exact path="/roots" component={Roots} />
                      <Route exact path="/games" component={Games} />
                      <Route exact path="/panels" component={Panels} />
                      <Route exact path="/roles" component={Roles} />
                      <Route exact path="/tags" component={Tags} />
                      <Route exact path="/users" component={User} />
                      <Route exact path="/devs" component={Dev} />
                      <Route exact path="/accessControl" component={AccessControl} />

                    </> : null
                }

              </Switch>
              {
                (!authenticated && !window.location.href.includes("/login")) ?
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div>
                      <h1>Admin Login</h1>
                      <button className='btn btn-success btn-lg' onClick={this.handleNavigation}>Login Page</button>
                    </div>
                  </div>
                  : null
              }

            </div>



          </div>


        </div>

      </div>
    );
  }
}
// const mapStateToProps = (state) => {
//   return {
//     authenticated: state.authenticated,
//     currentUser: state.currentUser,
//   };
// };


// const mapDispatchToProps = (dispatch) => {
//   return {
//     LogMeIn: (user) => dispatch(setLogin(user)),
//   };
// };



export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
