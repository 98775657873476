import React from "react";
import "./User.css";
import DevDatatable from "./DevDatatable";
import Sidebar from "../Common/Sidebar";

export const Dev = () => {

    return (
        <div className="p-3"><DevDatatable /></div>
    );
}