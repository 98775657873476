import React from "react";
// import "./Dashboard.css";
import RolesDatatable from "./RolesDatatable";

export const Roles = () => {

    return (

        <div className="p-3"><RolesDatatable /></div>



        // <div className="p-3"><RolesDatatable /></div>
    );
}