import { API_BASE_URL } from "../../../Constants"
import { api, apiHeader, axiosMultipart } from "../../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../../Configs/axiosUtils"

export const AuthAPI = {
    registerAdmin: async function (body, cancel = false) {
        const response = await api.request({
            url: "/auth/signup/admin",
            method: "POST",

            params: body,



            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

}

// defining the cancel API object for AuthAPI
const cancelApiObject = defineCancelApiObject(AuthAPI)