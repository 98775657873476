import { API_BASE_URL, ACCESS_TOKEN } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const TagsAPI = {
    addTags: async function (tags, cancel = false) {
        const response = await api.request({
            url: "/tag/add",
            method: "POST",
            params: {
                tags: tags,
            },

            headers: apiHeader,
            withCredentials: true,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // console.log(response);
        return response;
    },

    updateTag: async function (roleType, cancel = false) {
        const response = await api.request({
            url: "/tag/update",
            method: "POST",
            params: {
                roleType: roleType,
            },

            headers: apiHeader,
            withCredentials: true,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // console.log(response);
        return response;
    },

    deleteTags: async function (tagIds, cancel = false) {
        const response = await api.request({
            url: "/role/delete",
            method: "POST",
            params: {
                tagIds: tagIds,
            },

            headers: apiHeader,
            withCredentials: true,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // alert(response);
        return response;
    },

    findAll: async function (cancel = false) {
        const response = await api.request({
            url: "/tag/findAll",
            method: "GET",

            // data: {
            //     roleId: parseInt(roleId),
            //     roleType: roleType,
            // },

            headers: apiHeader,
            withCredentials: true,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // alert(response);
        return response.data;
    },
}

// defining the cancel API object for GameAPI
const cancelApiObject = defineCancelApiObject(TagsAPI)