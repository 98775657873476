import { API_BASE_URL } from "../../../Constants"
import { api, apiHeader, axiosMultipart } from "../../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../../Configs/axiosUtils"

export const AccessControlAPI = {
    findAll: async function (cancel = false) {
        const response = await api.request({
            url: "/accessControl/findAll",
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },
    add: async function (accessControlName, cancel = false) {
        const response = await api.request({
            url: "/accessControl/add",
            method: "POST",

            params: {
                accessControlName: accessControlName
            },
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

    update: async function (accessControlId, accessControlName, cancel = false) {
        const response = await api.request({
            url: "/accessControl/update",
            method: "POST",

            data: {
                accessControlId: accessControlId,
                accessControlName: accessControlName
            },
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

    delete: async function (accessControlName, cancel = false) {
        const response = await api.request({
            url: "/accessControl/delete",
            method: "POST",

            params: {
                accessControlName: accessControlName
            },
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

}

// defining the cancel API object for AccessControlAPI
const cancelApiObject = defineCancelApiObject(AccessControlAPI)