import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
import { Delete, DisabledByDefault, Edit } from '@mui/icons-material';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { gameColumns } from './GameConstants';
import axios from 'axios';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/map';
import { GameAPI } from '../../API/Endpoints/Backend/GameAPI';

const GameDatatable = (props) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState(() => []);
    const [validationErrors, setValidationErrors] = useState({});



    useEffect(() => {

        // axios
        //     .get("./gameData.json")
        //     .then((res) => {
        //         setTableData(res.data);
        //     });


        GameAPI.getAll()
            .then((res) => {
                setTableData(res);
            });

    }, []);


    const handleCreateNewRow = (values) => {

        console.log(values);
        tableData.push(values);

        setTableData([...tableData]);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            //send/receive api updates here, then refetch or update local table data for re-render
            setTableData([...tableData]);
            exitEditingMode(); //required to exit editing mode and close modal
        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                false
                // !confirm(`Are you sure you want to delete ${row.getValue('firstName')}`)
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData],
    );

    const handleDisableRow = useCallback(
        (row, availability) => {
            /* eslint-disable no-restricted-globals */
            const result = confirm("Are you sure you want to Disable");
            /* eslint-enable no-restricted-globals */

            if (
                !result
            ) {
                return;
            }


            GameAPI.setAvailability(row.original.gameId, availability)
                .then((res) => {
                    console.log(res);
                    tableData[row.index].gameEnabled = availability;
                    // tableData.splice(row.index, 1);
                    setTableData([...tableData]);
                });

        },
        [tableData],
    );

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });


    return (
        <>
            <ThemeProvider theme={darkTheme}>
                {
                    // (tableData != []) ?

                    <MaterialReactTable

                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                muiTableHeadCellProps: {
                                    align: 'center',
                                },
                                size: 120,
                            },
                            'mrt-row-select': {
                                enableColumnActions: true,
                                enableHiding: true,
                                size: 100,
                            },
                        }}
                        columns={useMemo(() =>
                            gameColumns
                        )}


                        data={tableData}
                        editingMode="modal" //default
                        enableColumnOrdering
                        enableEditing
                        onEditingRowSave={handleSaveRowEdits}
                        onEditingRowCancel={handleCancelRowEdits}

                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '1rem' }}>
                                {
                                    (props.userAccessControls.includes("GAME_EDIT") || (props.currentUser.roleId == 8)) ?
                                        <Tooltip arrow placement="left" title="Edit">
                                            <IconButton onClick={() => table.setEditingRow(row)}>
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>
                                        : null

                                }

                                {
                                    (props.userAccessControls.includes("GAME_DELETE") || (props.currentUser.roleId == 8)) ?
                                        <Tooltip arrow placement="right" title="Delete">
                                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                                <Delete />
                                            </IconButton>
                                        </Tooltip>
                                        : null

                                }

                                {
                                    (props.userAccessControls.includes("GAME_DISABLE") || (props.currentUser.roleId == 8)) ?
                                        <Tooltip arrow placement="right" title="Disable">
                                            {(tableData[row.index].userEnabled == true) ?
                                                <IconButton color="error" onClick={() => handleDisableRow(row, false)}>
                                                    <DisabledByDefault />
                                                </IconButton>

                                                :
                                                <IconButton color="success" onClick={() => handleDisableRow(row, true)}>
                                                    <DisabledByDefault />
                                                </IconButton>}
                                        </Tooltip> : null


                                }

                            </Box>
                        )}
                        renderTopToolbarCustomActions={() => (

                            (props.userAccessControls.includes("GAME_WRITE") || (props.currentUser.roleId == 8)) ? <Button
                                sx={{
                                    backgroundColor: "#ec412c",
                                    color: "#ffff"


                                }}
                                color="secondary"
                                onClick={() => setCreateModalOpen(true)}
                                variant="contained"
                            >
                                Upload New Game
                            </Button>
                                : null

                        )}
                    />
                }

            </ThemeProvider>

        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GameDatatable);
