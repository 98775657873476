import React from "react";
// import "./Dashboard.css";
import GameDatatable from "./GameDatatable";
import Sidebar from "../Common/Sidebar";

export const Games = () => {

    return (
        // <div className="dashboard d-flex">
        //     <div>
        //         <Sidebar />
        //     </div>
        //     <div style={{
        //         flex: "1 1 auto",

        //         display: "flex",
        //         flexFlow: "column",
        //         height: "100vh", overflowY: "scroll", background: "grey"
        //     }}>


        <div className="p-3"><GameDatatable /></div>


        // </div>
        // </div>
        // <div className="p-3"><GameDatatable /></div>
    );
}