import React from "react";
import "./User.css";
import UserDatatable from "./UserDatatable";
import Sidebar from "../Common/Sidebar";

export const User = () => {

    return (
        <div className="p-3"><UserDatatable /></div>
    );
}