import React from "react";
// import "./Dashboard.css";
import PanelDatatable from "./PanelDatatable";
import Sidebar from "../Common/Sidebar";

export const Panels = () => {

    return (

        <div className="p-3"><PanelDatatable /></div>



        // <div className="p-3"><PanelDatatable /></div>
    );
}