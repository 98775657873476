import React, { useEffect } from "react";
import "./Dashboard.css";
import AdminDatatable from "./AdminDatatable";
import Sidebar from "../Common/Sidebar";
import { ACCESS_TOKEN } from "../../API/Constants";
import { Redirect } from "react-router-dom";

export const Dashboard = () => {
    // useEffect(() => {
    //     window.location.reload();
    // }, [])


    // if (localStorage.getItem(ACCESS_TOKEN == null)) {
    //     return (
    //         <Redirect
    //             to={{
    //                 pathname: "/login",
    //                 state: { from: this.props.location }
    //             }} />
    //     )
    // }
    return (
        <div className="p-3"><AdminDatatable /></div>
    );
}