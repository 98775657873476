// src/redux/store.js

import { createStore } from 'redux';
import { AccessControlAPI } from '../API/Endpoints/Backend/AccessControl/AccessControlAPI';
import { ACCESS_TOKEN } from '../API/Constants';

const initialState = {
    authenticated: false,
    currentUser: null,
    userAccessControls: [],
    accessControls: []
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                authenticated: true,
                currentUser: action.payload
            };
        case 'LOGOUT':
            localStorage.removeItem(ACCESS_TOKEN);
            return {
                ...state,
                authenticated: false,
                currentUser: null,
                userAccessControls: [],
                accessControls: []
            };
        case 'UPDATE_ACCESS_CONTROLS':
            const updatedItems = [...action.payload];
            return {
                ...state,
                accessControls: updatedItems
            }

        case 'SET_USER_ACCESS_CONTROLS':

            return {
                ...state,
                userAccessControls: action.payload
            }
        default:
            return state;
    }
}

const store = createStore(rootReducer);

export default store;
