import React, { useEffect } from "react";
import RootDatatable from "./RootDatatable";

export const Roots = () => {
    // useEffect(() => {
    //     window.location.reload();
    // }, [])


    // if (localStorage.getItem(ACCESS_TOKEN == null)) {
    //     return (
    //         <Redirect
    //             to={{
    //                 pathname: "/login",
    //                 state: { from: this.props.location }
    //             }} />
    //     )
    // }
    return (
        <div className="p-3"><RootDatatable /></div>
    );
}