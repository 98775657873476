import { API_BASE_URL } from "../../../Constants"
import { api, apiHeader, axiosMultipart } from "../../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../../Configs/axiosUtils"

export const UserAccessControlAPI = {
    assignAndUnassign: async function (userId, accessControlIds, cancel = false) {
        const response = await api.request({
            url: "/userAccessControl",
            method: "POST",

            data: {
                userId: userId,
                accessControlIds: accessControlIds

            },
            headers: apiHeader,
            withCredentials: true,


            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

    assign: async function (userId, accessControlIds, cancel = false) {
        const response = await api.request({
            url: "/userAccessControl/assign",
            method: "POST",

            data: {
                userId: userId,
                accessControlIds: accessControlIds

            },
            headers: apiHeader,
            withCredentials: true,


            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

}

// defining the cancel API object for UserAccessControlAPI
const cancelApiObject = defineCancelApiObject(UserAccessControlAPI)