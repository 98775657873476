import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    IconButton,
    Stack,
    Checkbox,
    Tooltip,
    FormControlLabel,
    FormControl,
} from '@mui/material';

import { Delete, DisabledByDefault, Edit } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { AccessControlAPI } from '../../API/Endpoints/Backend/AccessControl/AccessControlAPI';

import { UserAPI } from '../../API/Endpoints/Backend/AccessControl/UserAPI';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/map';
import { UserAccessControlAPI } from '../../API/Endpoints/Backend/AccessControl/UserAccessControlAPI';
import { CreateNewAccountModal } from './CreateNewAccount';

const DevDatatable = (props) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});


    let checkedRights = [];


    const userAccessControls = useSelector(state => state.userAccessControls);


    useEffect(() => {
        UserAPI.findAllByRoleType("ROLE_DEV")
            .then(res => setTableData(res));

        AccessControlAPI.findAll()
            .then((res) =>
                props.SetAccessControls(res)

            );
    }, [])


    const handleCreateNewRow = (values) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            console.log(values);

            values.userAccessControlList = checkedRights;

            //AccessControlIDs
            // var val = values.userAccessControlList.map(x => x.accessControlId);
            var val = checkedRights.map(x => x.accessControlId);

            console.log(val);
            // values.tSSoList();
            tableData[row.index] = values;
            //send/receive api updates here, then refetch or update local table data for re-render
            UserAccessControlAPI.assignAndUnassign(parseInt(values.userId), val)
                .then((res) => {


                    console.log(res)
                    setTableData([...tableData]);
                    exitEditingMode(); //required to exit editing mode and close modal


                });
        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDisableRow = useCallback(
        (row, availability) => {
            /* eslint-disable no-restricted-globals */
            const result = confirm("Are you sure you want to Disable");
            /* eslint-enable no-restricted-globals */

            if (
                !result
            ) {
                return;
            }


            UserAPI.setAvailability(row.original.userId, availability)
                .then((res) => {
                    console.log(res);
                    tableData[row.index].userEnabled = availability;
                    // tableData.splice(row.index, 1);
                    setTableData([...tableData]);
                });

        },
        [tableData],
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );



    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });



    const columns = useMemo(
        () => [
            {
                accessorKey: 'userId',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,

            },
            {
                accessorKey: 'userName',
                header: 'Name',
                size: 50,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),

            },
            {
                accessorKey: 'email',
                header: 'Email',
                enableEditing: false,
                // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                //     ...getCommonEditTextFieldProps(cell),
                //     type: 'email',
                // }),
            },
            {
                accessorKey: 'userEnabled',
                header: 'User Enabled',
                enableEditing: false,

                Cell: ({ row, cell }) => {
                    return (
                        (cell.getValue()) ?
                            <span className='p-2 bg-success rounded-3'>
                                {cell.getValue().toString().toUpperCase()}
                            </span>
                            :
                            <span className='p-2 red-bg rounded-3'>
                                {cell.getValue().toString().toUpperCase()}
                            </span>
                    );
                }
            },
        ],
        [checkedRights]


    );

    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <MaterialReactTable

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={tableData}
                    editingMode="modal" //default
                    enableColumnOrdering
                    enableEditing
                    enableRowActions
                    onEditingRowSave={handleSaveRowEdits}
                    onEditingRowCancel={handleCancelRowEdits}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            {
                                (props.userAccessControls.includes("DEV_EDIT") || (props.currentUser.roleId == 8)) ?
                                    <Tooltip arrow placement="left" title="Edit">
                                        <IconButton onClick={() => table.setEditingRow(row)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    : null

                            }

                            {
                                (props.userAccessControls.includes("DEV_DELETE") || (props.currentUser.roleId == 8)) ?
                                    <Tooltip arrow placement="right" title="Disable">
                                        {(tableData[row.index].userEnabled == true) ?
                                            <IconButton color="error" onClick={() => handleDisableRow(row, false)}>
                                                <DisabledByDefault />
                                            </IconButton>

                                            :
                                            <IconButton color="success" onClick={() => handleDisableRow(row, true)}>
                                                <DisabledByDefault />
                                            </IconButton>}
                                    </Tooltip> : null
                            }

                        </Box>
                    )}

                />
            </ThemeProvider>

        </>
    );
};


const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default connect(mapStateToProps, mapDispatchToProps)(DevDatatable);
