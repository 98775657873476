import React, { Component } from 'react';


import { getCurrentUser, login } from '../../../API/Configs/APIUtils';
import { Link, Redirect } from 'react-router-dom'
import Alert from 'react-s-alert';
import './Login.css';


import { connect } from 'react-redux';
import { selectAuthenticated, selectCurrentUser } from '../../../Redux/selectors';
import { useDispatch } from 'react-redux';
import { setLogin } from '../../../Redux/actions';
import { ACCESS_TOKEN } from '../../../API/Constants';
import { mapDispatchToProps, mapStateToProps } from '../../../Redux/map';


class Login extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {
        // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        // if (this.props.location.state && this.props.location.state.error) {
        //     setTimeout(() => {
        //         Alert.error(this.props.location.state.error, {
        //             timeout: 5000
        //         });
        //         this.props.history.replace({
        //             pathname: this.props.location.pathname,
        //             state: {}
        //         });
        //     }, 100);
        // }
    }

    render() {
        if (this.props.authenticated) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { from: this.props.location }
                }} />;
        }

        return (
            <div className="login-container" style={{
                flex: "1 1 auto",
                // alignContent: "center",
                justifyContent: "center",
                alignItems: "center",

                display: "flex",
                flexFlow: "column",
                height: "100vh", overflowY: "hidden", background: "grey"
            }} >
                <div className="login-content login-card">
                    <h1 className="login-title text-start">Login to <span style={{ color: "#E13536" }}>Playtube</span> Admin</h1>
                    <LoginForm {...this.props} />
                </div>
            </div>
        );
    }
}



class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const inputName = target.name;
        const inputValue = target.value;

        this.setState({
            [inputName]: inputValue
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const loginRequest = Object.assign({}, this.state);


        login(loginRequest)
            .then(response => {
                // console.log(response);

                var temp = response.accessToken;

                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                // const dispatch = useDispatch();
                // localStorage.setItem(ACCESS_TOKEN, temp);

                getCurrentUser()
                    .then(response => {

                        console.log(response);
                        if (response.roleId == 8 || response.roleId == 9) {
                            const { LogMeIn } = this.props;
                            LogMeIn(response);
                            console.log("Admin Ha");
                        }
                        else {
                            localStorage.removeItem(ACCESS_TOKEN);
                            console.log("User Ha");
                        }


                        // response.


                        // dispatch(setLogin(response));
                        // this.props.history.push("/");
                        //   this.setState({
                        //     currentUser: response,
                        //     authenticated: true,
                        //     loading: false
                        //   });
                    });

                // this.props.onLogin();
                // console.log(this.props.authenticated);
                Alert.success("You're successfully logged in!");

                this.props.history.push("/");
            }).catch(error => {
                Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }

    render() {

        return (
            <form onSubmit={this.handleSubmit}>

                <div className='form-group text-start ps-3 pt-1 rounded-3 bg-dark' style={{ border: "2px solid" }}>
                    <input type="radio" name="roleRadio" />
                    <b> Admin</b>
                    <p className='ms-3 text-white-50'>Account owner that performs tasks requiring unrestricted access.</p>
                </div>

                <div className='form-group text-start ps-3 pt-3 rounded-3 mt-3 bg-dark' style={{ border: "2px solid" }}>
                    <input type="radio" name="roleRadio" />
                    <b> Moderator User</b>
                    <p className='ms-3 text-white-50'>User within an account that performs daily tasks.</p>
                </div>



                {/* Email */}
                <div className="text-start form-group text-white mb-4 mt-4">
                    <label data-testid="name" htmlFor='email'>Email</label>
                    <input required type="text" onChange={this.handleInputChange} value={this.state.email} name="email" className="form-control bg-dark text-white" id="email" placeholder="Enter Your Email" />
                </div>



                {/* Password */}
                <div className="text-start form-group text-white mb-4">
                    <label data-testid="password" htmlFor='password'>Password</label>
                    <input required type="password" onChange={this.handleInputChange} value={this.state.password} name="password" className="form-control bg-dark text-white" id="password" placeholder="Enter Your Password" />
                </div>

                <p className='text-start'>By continuing, you agree to the Playtube Customer Agreement or other agreement for Playtube services, and the Privacy Notice.</p>


                <div className="form-group red-bg rounded-2">
                    <input type="submit" value={"Login"} className="btn btn-block red-bg text-white" />
                </div>
            </form>

        );
    }
}


// const mapStateToProps = (state) => {
//     return {
//         authenticated: state.authenticated,
//         currentUser: state.currentUser,
//     };
// };


// const mapDispatchToProps = (dispatch) => {
//     return {
//         LogMeIn: (user) => dispatch(setLogin(user)),
//     };
// };

export default connect(mapStateToProps, mapDispatchToProps)(Login);
