import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Checkbox,
    Tooltip,
    FormGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Select,
    Avatar,
} from '@mui/material';
import { Delete, Edit, Save } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { RolesAPI } from '../../API/Endpoints/Backend/RolesAPI';
import { AccessControlAPI } from '../../API/Endpoints/Backend/AccessControl/AccessControlAPI';


//example of creating a mui dialog modal for creating new rows
export const CreateNewAccessControlModal = ({ open, columns, onClose, onSubmit }) => {

    // const [accessControls, setAccessControls] = useState([]);

    // const [roleId, setRoleId] = useState("");
    // const [email, setEmail] = useState("");
    // const [password, setPassword] = useState("");
    const [accessControl, setaccessControl] = useState("");

    const handleSubmit = () => {

        AccessControlAPI.add(accessControl)
            .then(response => { console.log(response) });

        var temp = {
            accessControlName: accessControl
        }

        onSubmit(temp);
        onClose();
    };

    useEffect(() => {
        // AccessControlAPI.findAll()
        //     .then(res => {
        //         setAccessControls(res);
        //     });
    }, [])


    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create New Access Control</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >

                        <TextField
                            key="accessControl"
                            label="accessControl"
                            name="accessControl"
                            value={accessControl}
                            onChange={(e) =>
                                setaccessControl(e.target.value)
                            }
                        />
                    </Stack>
                </form>
            </DialogContent>

            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Create New Access Control
                </Button>
            </DialogActions>
        </Dialog>
    );
};
