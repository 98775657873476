import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Delete, Edit } from '@mui/icons-material';
import { data, panelData, } from '../Common/makeData';
// import { panelColumns } from './PanelConstants';
import {
    Box,
    Button,
    IconButton,
    ThemeProvider,
    Tooltip,
    createTheme,
} from '@mui/material';
import { PanelAPI } from '../../API/Endpoints/Backend/PanelAPI';
import { ImagePopup } from '../Common/ImagePopup';
import { RolesAPI } from '../../API/Endpoints/Backend/RolesAPI';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/map';
import { CreateNewRoleModal } from './CreateNewRole';


const RolesDatatable = (props) => {
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {


            RolesAPI.update(values.roleId, values.roleType)
                .then(response => {
                    console.log(response);
                    if (response.status == 200)
                        console.log("Role Updated Successfully!");
                })
                .catch(error => {
                    console.log("Aya Tha");
                    console.error(error);
                    // Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode(); //required to exit editing mode and close modal
        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };
    const handleCreateNewRow = (values) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    const handleDeleteRow = useCallback(
        (row) => {

            /* eslint-disable no-restricted-globals */
            const result = confirm("Are you sure you want to delete");
            /* eslint-enable no-restricted-globals */

            if (

                !result
            ) {
                return;
            }

            // console.log(row.getValue('userId'));

            // if (!(row.getValue('userId') == 16)) {

            RolesAPI.delete(row.getValue('roleType'))
                .then(response => {
                    console.log(response);
                    tableData.splice(row.index, 1);
                    setTableData([...tableData]);
                    // if (response.status == 200)
                    //     console.log("Panel Updated Successfully!");
                })
                .catch(error => {
                    console.error(error);
                });
        },




        [tableData],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'roleId',
                header: 'RoleID',
                enableColumnOrdering: false,
                enableEditing: true, //disable editing on this column

                size: 80,

            },
            {
                accessorKey: 'roleType',
                header: 'RoleType',
                enableEditing: true,

            },
        ]
    );

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const columnDef = {
        'mrt-row-actions': {
            muiTableHeadCellProps: {
                align: 'center',
            },
            size: 120,
        },
    };



    useEffect(() => {

        RolesAPI.findAll()
            .then((res) => {
                console.log(res);
                setTableData(res);
            });

    }, []);


    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <MaterialReactTable

                    displayColumnDefOptions={columnDef}
                    columns={columns}
                    data={tableData}
                    editingMode="modal" //default
                    enableColumnOrdering
                    enableEditing
                    onEditingRowSave={handleSaveRowEdits}
                    onEditingRowCancel={handleCancelRowEdits}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            {
                                (props.userAccessControls.includes("ROLE_EDIT")) ?
                                    <Tooltip arrow placement="left" title="Edit">
                                        <IconButton onClick={() => table.setEditingRow(row)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    : null
                            }

                            {
                                (props.userAccessControls.includes("ROLE_DELETE")) ?
                                    <Tooltip arrow placement="right" title="Delete">
                                        <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                    : null
                            }
                        </Box>
                    )}
                    renderTopToolbarCustomActions={() => (

                        (props.userAccessControls.includes("ROLE_WRITE")) ? <Button
                            sx={{
                                backgroundColor: "#ec412c"

                            }}

                            // color="secondary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                        >
                            Create New Role
                        </Button> : null
                    )}
                />
                <CreateNewRoleModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                />

            </ThemeProvider>

        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesDatatable);