import React from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';

import { ACCESS_TOKEN } from '../../API/Constants';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/map';
import { useEffect } from 'react';



const Sidebar = (props) => {

    const LogmeOut = () => {
        props.LogMeOut();
        localStorage.removeItem(ACCESS_TOKEN);

        // const history = useHistory();
        // history.push('/login');

    }

    useEffect(() => {


    }, [])

    const CheckReadRight = (right) => {
        return props.userAccessControls.some((word) => word.includes(right));
    }

    return (
        <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
            <CDBSidebar textColor="#fff" backgroundColor="#1B1B1B">
                <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
                    <a href="/">
                        <img src="images/logo.png" alt="" />
                    </a>
                </CDBSidebarHeader>

                <CDBSidebarContent className="sidebar-content">
                    <CDBSidebarMenu>

                        {
                            (props.currentUser.roleId == 8) ?
                                <NavLink exact to="/roots" activeClassName="activeClicked">
                                    <CDBSidebarMenuItem icon="columns">Roots</CDBSidebarMenuItem>
                                </NavLink>
                                : <></>
                        }

                        {/* {
                            (props.currentUser.roleId == 8) ? */}
                        <NavLink exact to="/" activeClassName="activeClicked">
                            <CDBSidebarMenuItem icon="columns">Admins</CDBSidebarMenuItem>
                        </NavLink>
                        {/* : <></>
                        } */}

                        {
                            (CheckReadRight("USER") || props.currentUser.roleId == 8) ?
                                <NavLink exact to="/users" activeClassName="activeClicked">
                                    <CDBSidebarMenuItem icon="user">Users</CDBSidebarMenuItem>
                                </NavLink>
                                : <></>
                        }


                        {
                            (CheckReadRight("DEV") || props.currentUser.roleId == 8) ?
                                <NavLink exact to="/devs" activeClassName="activeClicked">
                                    <CDBSidebarMenuItem icon="fa fa-star">Developers</CDBSidebarMenuItem>
                                </NavLink>
                                : <></>
                        }

                        {
                            (CheckReadRight("GAME") || props.currentUser.roleId == 8) ?
                                <NavLink exact to="/games" activeClassName="activeClicked">
                                    <CDBSidebarMenuItem icon="fa-solid fa-gamepad">Games</CDBSidebarMenuItem>
                                </NavLink>
                                : <></>
                        }

                        {
                            (CheckReadRight("PANEL") || props.currentUser.roleId == 8) ?
                                <NavLink exact to="/panels" activeClassName="activeClicked">
                                    <CDBSidebarMenuItem icon="home">Panels</CDBSidebarMenuItem>
                                </NavLink>
                                : <></>
                        }

                        {
                            (CheckReadRight("ROLE") || props.currentUser.roleId == 8) ?
                                <NavLink exact to="/roles" activeClassName="activeClicked">
                                    <CDBSidebarMenuItem icon="circle">Roles</CDBSidebarMenuItem>
                                </NavLink>
                                : <></>
                        }

                        {
                            (CheckReadRight("TAGS") || props.currentUser.roleId == 8) ?
                                <NavLink exact to="/tags" activeClassName="activeClicked">
                                    <CDBSidebarMenuItem icon="tags">Tags</CDBSidebarMenuItem>
                                </NavLink>
                                : <></>
                        }

                        {
                            (props.currentUser.roleId == 8) ?
                                <NavLink exact to="/accessControl" activeClassName="activeClicked">
                                    <CDBSidebarMenuItem icon="square">Access Controls</CDBSidebarMenuItem>
                                </NavLink>
                                : <></>
                        }

                    </CDBSidebarMenu>
                </CDBSidebarContent>

                <CDBSidebarFooter style={{ textAlign: 'center' }}>
                    <div
                        style={{
                            padding: '20px 5px',
                        }}
                    >
                        <button className='btn btn-danger' onClick={props.LogMeOut} >Logout</button>
                    </div>
                </CDBSidebarFooter>
            </CDBSidebar>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);