import React from "react";
// import "./Dashboard.css";
import TagsDatatable from "./TagsDatatable";

export const Tags = () => {

    return (

        <div className="p-3"><TagsDatatable /></div>

    );
}