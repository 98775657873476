import { API_BASE_URL } from "../../../Constants"
import { api, apiHeader, axiosMultipart } from "../../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../../Configs/axiosUtils"

export const UserAPI = {
    findAllByRoleType: async function (roleType, cancel = false) {
        const response = await api.request({
            url: "/user/findAllByRoleType",
            method: "POST",

            params: {
                roleType: roleType,
            },

            withCredentials: true,
            headers: apiHeader,



            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

    deleteUser: async function (userId, cancel = false) {
        const response = await api.request({
            url: "/user/deleteByUser",
            method: "POST",

            params: {
                userId: userId,
            },

            withCredentials: true,
            headers: apiHeader,



            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

    setAvailability: async function (userId, availability, cancel = false) {
        const response = await api.request({
            url: "/user/availability",
            method: "POST",

            params: {
                userId: userId,
                availability: availability
            },

            withCredentials: true,
            headers: apiHeader,



            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

}

// defining the cancel API object for UserAPI
const cancelApiObject = defineCancelApiObject(UserAPI)