import { useMemo } from "react";
import { ImagePopup } from "../Common/ImagePopup";
// import gamesJson from "./data/gameData.json";

export const gameColumns = [
    {
        accessorKey: 'gameId',
        header: 'gameId',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column

    },
    {
        accessorKey: 'gameTitle',
        header: 'gameTitle',
        enableEditing: false,
    },
    {
        accessorKey: 'gameEnabled',
        header: 'gameEnabled',
        enableEditing: false,

        Cell: ({ row, cell }) => {
            return (
                (cell.getValue()) ?
                    <span className='p-2 bg-success rounded-3'>
                        {cell.getValue().toString().toUpperCase()}
                    </span>
                    :
                    <span className='p-2 red-bg rounded-3'>
                        {cell.getValue().toString().toUpperCase()}
                    </span>
            );
        }
    },
    {
        accessorKey: 'gameDescription',
        header: 'gameDescription',
        Cell: ({ value }) => (
            <div
                style={{
                    whiteSpace: 'nowrap', // prevents text from wrapping
                    overflow: 'hidden', // hides overflowing text
                    textOverflow: 'ellipsis', // adds ellipsis to indicate truncated text
                    maxHeight: '3.6em', // limits height to 2 lines of text
                    lineHeight: '1.8em', // sets line height to match max-height
                }}
            >
                {value}
            </div>
        ),
    },
    {
        accessorKey: 'genreName',
        header: 'genreName',
    },
    {
        accessorKey: 'cover',
        header: 'cover',
        Cell: ({ cell }) => <ImagePopup imageUrl={(cell.getValue() != undefined) ? cell.getValue().toString() : ""} />,
        // Cell: ({ cell }) => <img width={50} height={50} src={(cell.getValue() != undefined) ? cell.getValue().toString() : ""} />,
    },
    {
        accessorKey: 'video',
        header: 'video',
        Cell: ({ cell }) => <ImagePopup imageUrl={(cell.getValue() != undefined) ? cell.getValue().toString() : ""} />,

    },
    // {
    //     accessorKey: 'gameScreenShotList',
    //     header: 'gameScreenShotList',
    //     Cell: ({ cell }) => {
    //         <p>Hello WOrld</p>
    //         // <img width={50} height={50} src={cell.getValue()[0].screenShot.toString()} />
    //         // <div>
    //         //     { // console.log(cell.getValue()[0].screenShot);
    //         //         cell.getValue().map(c =>
    //         //             // console.log(c.screenShot)
    //         //             <img width={50} height={50} src={c.screenShot} />
    //         //         )
    //         //     }
    //         // </div>
    //     }
    //     // <img width={50} height={50} src={cell.getValue().toString()} />,
    // },
    // {
    //     accessorKey: 'profileImageUrl',
    //     header: 'Image',
    //     Cell: ({ cell }) => <img width={50} height={50} src={cell.getValue().toString()} />,
    //     Edit: ({ cell, column, table }) => <>Profile Image<input type="file" /></>,
    // },
    // {
    //     accessorKey: 'coverImageUrl',
    //     header: 'coverImageUrl',
    //     Cell: ({ cell }) => <img width={50} height={50} src={cell.getValue().toString()} />,
    //     Edit: ({ cell, column, table }) => <>Cover Image<input type="file" /></>,
    // },


    // {
    //     accessorKey: 'panelDescription',
    //     header: 'PanelDescription',
    //     size: 140,
    // },
    // {
    //     accessorKey: 'panelUniqueName',
    //     header: 'Panel Unique',
    //     enableEditing: false,
    // },
    // {
    //     accessorKey: 'panelTotalLikes',
    //     header: 'Likes',
    //     enableEditing: false,

    // },
    // {
    //     accessorKey: 'panelTotalPlays',
    //     header: 'Plays',
    //     enableEditing: false,
    //     size: 80,

    // },
    // {
    //     accessorKey: 'panelTotalPlaytime',
    //     header: 'Playtime',
    //     enableEditing: false,

    // },
];






// console.log(JSON.parse(gamesJson));
// export const gameData = require('./data/gameData.json')[0];
// console.log(require('./data/gameData.json')[0]);
// data = require('json!./data.json');
