import React from "react";
// import "./Dashboard.css";
import AccessControlDatatable from "./AccessControlDatatable";

export const AccessControl = () => {

    return (

        <div className="p-3"><AccessControlDatatable /></div>



        // <div className="p-3"><AccessControlDatatable /></div>
    );
}