import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    IconButton,
    Stack,
    Checkbox,
    Tooltip,
    FormControlLabel,
    FormControl,
} from '@mui/material';

import { Delete, Edit } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { AccessControlAPI } from '../../API/Endpoints/Backend/AccessControl/AccessControlAPI';

import { UserAPI } from '../../API/Endpoints/Backend/AccessControl/UserAPI';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/map';
import { UserAccessControlAPI } from '../../API/Endpoints/Backend/AccessControl/UserAccessControlAPI';
import { CreateNewAccountModal } from '../Users/CreateNewAccount';

const RootDatatable = (props) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});


    let checkedRights = [];


    const userAccessControls = useSelector(state => state.userAccessControls);


    useEffect(() => {
        UserAPI.findAllByRoleType("ROLE_ROOT")
            .then(res => setTableData(res));

        AccessControlAPI.findAll()
            .then((res) =>
                props.SetAccessControls(res)

            );
    }, [])


    const handleCreateNewRow = (values) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            console.log(values);

            values.userAccessControlList = checkedRights;

            //AccessControlIDs
            // var val = values.userAccessControlList.map(x => x.accessControlId);
            var val = checkedRights.map(x => x.accessControlId);

            console.log(val);
            // values.tSSoList();
            tableData[row.index] = values;
            //send/receive api updates here, then refetch or update local table data for re-render
            UserAccessControlAPI.assignAndUnassign(parseInt(values.userId), val)
                .then((res) => {


                    console.log(res)
                    setTableData([...tableData]);
                    exitEditingMode(); //required to exit editing mode and close modal


                });
        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {
            /* eslint-disable no-restricted-globals */
            const result = confirm("Are you sure you want to delete");
            /* eslint-enable no-restricted-globals */

            if (

                !result
            ) {
                return;
            }
            //send api delete request here, then refetch or update local table data for re-render
            // console.log(row.original.userId);
            UserAPI.deleteUser(row.original.userId)
                .then((res) => {
                    console.log(res);
                    tableData.splice(row.index, 1);
                    setTableData([...tableData]);
                });


        },
        [tableData],
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : cell.column.id === 'age'
                                ? validateAge(+event.target.value)
                                : validateRequired(event.target.value);
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );



    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }




    const globalTheme = useTheme();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });



    const columns = useMemo(
        () => [
            {
                accessorKey: 'userId',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 80,

            },
            {
                accessorKey: 'userName',
                header: 'Name',
                size: 50,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                // Cell: ({ row, cell }) => {
                //     var val = (cell.getValue() != undefined) ? cell.getValue() : "";
                //     return (

                //         <>
                //             {/* <span className='p-3 rounded-5 avatar' style={{ backgroundColor: getRandomColor() }}>{cell.getValue().toString()[0]}</span> */}
                //             <i className='fa fa-user-circle me-2' style={{ color: `${getRandomColor()}`, fontSize: "30px" }}> </i><div>{cell.getValue().toString()}</div>
                //         </>
                //         // </Tooltip>


                //         //     );
                //         // }
                //     );
                // }
            },
            {
                accessorKey: 'email',
                header: 'Email',
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'email',
                }),
            },
            {
                accessorKey: 'userAccessControlList',
                header: 'Access Controls',

                enableColumnOrdering: false,
                enableSorting: false,
                size: 80,

                Cell: ({ row, cell }) => {
                    var accessRights = (cell.getValue() != undefined) ? cell.getValue() : "";
                    // console.log(accessRights);
                    return (
                        <FormControl component="fieldset">

                            <Stack direction="column">
                                {accessRights.map(x =>
                                    <div className='red-bg m-1 p-1 text-center rounded-2'> {x.accessControlName + " "}</div>
                                )}

                            </Stack>

                        </FormControl>
                    );
                },

                Edit: ({ row, cell }) => {

                    var accessRights = (cell.getValue() != undefined) ? cell.getValue() : "";
                    // checkedRights = accessRights;

                    console.log(props.userAccessControls);
                    // checkedRights.push();

                    const filteredAccessControls = props.accessControls.filter(
                        accessControl =>
                            !accessRights.some(
                                userAccessControl => userAccessControl.accessControlId === accessControl.accessControlId
                            )
                    );

                    return (
                        <FormControl component="fieldset">

                            <Stack direction="column">
                                {/* {

                                    accessRights.map(x =>
                                        // <div> {x.accessControlName + " "}</div>
                                        <FormControlLabel control={<Checkbox value={x} onChange={(event) => {
                                            if (event.target.checked) {
                                                checkedRights.push(JSON.stringify(event.target.value));
                                            }
                                            else {
                                                event.target.checked = false;
                                                const objToRemove = x;
                                                const indexToRemove = accessRights.findIndex(obj => JSON.stringify(obj) === JSON.stringify(objToRemove));
                                                checkedRights.splice(indexToRemove, 1);
                                                // unCheckedRights.push(x);
                                            }

                                            // console.log(checkedRights);
                                            // console.log(unCheckedRights);
                                            // console.log(event.target.value);
                                        }} />}
                                            label={x.accessControlName} />

                                    )} */}


                                {
                                    props.accessControls.map(x =>
                                        // <div> {x.accessControlName + " "}</div>
                                        <FormControlLabel control={<Checkbox value={x} onChange={(event) => {
                                            if (event.target.checked) {
                                                checkedRights.push(x);
                                            }
                                            else {
                                                event.target.checked = false;
                                                const objToRemove = x;
                                                const indexToRemove = accessRights.findIndex(obj => JSON.stringify(obj) === JSON.stringify(objToRemove));
                                                checkedRights.splice(indexToRemove, 1);
                                                // unCheckedRights.push(x);
                                            }
                                            console.log(checkedRights);
                                            // else {
                                            //     unCheckedRights.push(event.target.value);
                                            // }

                                            // console.log(checkedRights);
                                            // console.log(unCheckedRights);
                                            // console.log(event.target.value);
                                        }} />} label={x.accessControlName} />

                                    )}



                            </Stack>



                        </FormControl>
                    );
                }


            },

        ],
        [checkedRights]


    );


    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === 'light'
                                ? 'rgb(254,255,244)' //random light yellow color for the background in light mode
                                : '#000', //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: 'none', //customize typography styles for all buttons in table by default
                        fontSize: '1.2rem',
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: '1.1rem', //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme],
    );

    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <MaterialReactTable

                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    columns={columns}
                    data={tableData}
                    editingMode="modal" //default
                    enableColumnOrdering
                    enableEditing
                    enableRowActions
                    onEditingRowSave={handleSaveRowEdits}
                    onEditingRowCancel={handleCancelRowEdits}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>

                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton onClick={() => table.setEditingRow(row)}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>


                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                    <Delete />
                                </IconButton>
                            </Tooltip>

                        </Box>
                    )}
                // renderTopToolbarCustomActions={() => (

                //     // <Button
                //     //     sx={{
                //     //         backgroundColor: "#ec412c"

                //     //     }}

                //     //     // color="secondary"
                //     //     onClick={() => setCreateModalOpen(true)}
                //     //     variant="contained"
                //     // >
                //     //     Create New Account
                //     // </Button>
                // )}
                />
                <CreateNewAccountModal
                    columns={columns}
                    open={createModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onSubmit={handleCreateNewRow}
                />

            </ThemeProvider>

        </>
    );
};


const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
const validateAge = (age) => age >= 18 && age <= 50;

export default connect(mapStateToProps, mapDispatchToProps)(RootDatatable);
