import { setAccessControl, setLogin, setLogout, setUserAccessControls } from "./actions";

export const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        currentUser: state.currentUser,
        accessControls: state.accessControls,
        userAccessControls: state.userAccessControls

    };
};


export const mapDispatchToProps = (dispatch) => {
    return {
        LogMeIn: (user) => dispatch(setLogin(user)),
        LogMeOut: () => dispatch(setLogout()),
        SetAccessControls: (data) => dispatch(setAccessControl(data)),
        setUserAccessControls: (data) => dispatch(setUserAccessControls(data))
    };
};