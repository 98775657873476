import { API_BASE_URL, ACCESS_TOKEN } from "../../Constants"
import { api, apiHeader } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const RolesAPI = {
    findAll: async function (cancel = false) {
        const response = await api.request({
            url: "/role/findAll",
            method: "GET",
            // params: {
            //     gameId: gameId,
            // },

            headers: apiHeader,
            withCredentials: true,
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // console.log(response);
        return response.data;
    },

    add: async function (roleType, cancel = false) {
        const response = await api.request({
            url: "/role/add",
            method: "POST",
            params: {
                roleType: roleType,
            },

            headers: apiHeader,
            withCredentials: true,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // console.log(response);
        return response;
    },

    delete: async function (roleType, cancel = false) {
        const response = await api.request({
            url: "/role/delete",
            method: "POST",
            params: {
                roleType: roleType,
            },

            headers: apiHeader,
            withCredentials: true,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // alert(response);
        return response;
    },

    update: async function (roleId, roleType, cancel = false) {
        const response = await api.request({
            url: "/role/update",
            method: "POST",

            data: {
                roleId: parseInt(roleId),
                roleType: roleType,
            },

            // headers: apiHeader,
            // withCredentials: true,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        // alert(response);
        return response;
    },
}

// defining the cancel API object for GameAPI
const cancelApiObject = defineCancelApiObject(RolesAPI)