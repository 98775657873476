// src/redux/actions.js

// export function setActiveGameTag(tag) {
//     return { type: 'SET_ACTIVE_GAME_TAG', payload: tag };
// }


// export function setTagGames(games) {
//     return {
//         type: 'SET_TAG_GAMES',
//         payload: games,
//     };
// }

export const setLogin = (user) => {
    return {
        type: 'LOGIN',
        payload: user
    };
};

export const setLogout = () => {
    return {
        type: 'LOGOUT'
    };
};

export const setAccessControl = (data) => {
    return {
        type: 'UPDATE_ACCESS_CONTROLS',
        payload: data
    };
};


export const setUserAccessControls = (data) => {
    return {
        type: 'SET_USER_ACCESS_CONTROLS',
        payload: data
    };
};



