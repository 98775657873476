import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Delete, Edit } from '@mui/icons-material';
import { data, panelData, } from '../Common/makeData';
import { panelColumns } from './PanelConstants';
import {
    Box,
    Button,
    IconButton,
    ThemeProvider,
    Tooltip,
    createTheme,
} from '@mui/material';
import { PanelAPI } from '../../API/Endpoints/Backend/PanelAPI';
import { ImagePopup } from '../Common/ImagePopup';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from '../../Redux/map';


const PanelDatatable = (props) => {
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});



    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            // tableData[row.index] = values;

            //send/receive api updates here, then refetch or update local table data for re-render

            let panelFormData = new FormData();
            panelFormData.append("userId", values.userId);
            panelFormData.append("name", values.name);
            panelFormData.append("panelDescription", values.panelDescription);

            if (document.getElementById("coverImage").files[0] != undefined) {
                panelFormData.append("coverImage", document.getElementById("coverImage").files[0])
            }

            if (document.getElementById("profileImage").files[0] != undefined) {
                panelFormData.append("profileImage", document.getElementById("profileImage").files[0])
            }


            for (let pair of panelFormData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }

            PanelAPI.updatePanelByAdmin(panelFormData)
                .then(response => {
                    console.log(response);
                    // if (response.status == 200)
                    console.log("Panel Updated Successfully!");
                    // setTableData([...tableData]);
                    exitEditingMode(); //required to exit editing mode and close modal
                })
                .catch(error => {
                    console.log("Aya Tha");
                    console.error(error);
                    // Alert.error((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });


        }
    };

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
        (row) => {

            /* eslint-disable no-restricted-globals */
            const result = confirm("Are you sure you want to delete");
            /* eslint-enable no-restricted-globals */

            if (

                !result
            ) {
                return;
            }

            console.log(row.getValue('userId'));

            if (!(row.getValue('userId') == 16)) {

                PanelAPI.deletePanelById(row.getValue('userId'))
                    .then(response => {
                        console.log(response);
                        tableData.splice(row.index, 1);
                        setTableData([...tableData]);
                        // if (response.status == 200)
                        //     console.log("Panel Updated Successfully!");
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }



        },
        [tableData],
    );

    const columns = useMemo(
        () => [

            {
                accessorKey: 'profileImageUrl',
                header: 'Profile Image',
                Cell: ({ cell }) => <ImagePopup imageUrl={(cell.getValue() != undefined) ? cell.getValue().toString() : ""} />,
                Edit: ({ cell, column, table }) => <>Profile Image<input accept='image/*' name='profileImage' id='profileImage' type="file" size="60" onChange={(event) => {
                }} /></>,

                outerWidth: 20,
                size: 50

            },
            {
                accessorKey: 'coverImageUrl',
                header: 'Cover Image',
                Cell: ({ cell }) => <ImagePopup imageUrl={(cell.getValue() != undefined) ? cell.getValue().toString() : ""} />,
                Edit: ({ cell, column, table }) => <>Cover Image<input type="file" accept='image/*' name='coverImage' id='coverImage' size="60" /></>,

            },
            {
                accessorKey: 'name',
                header: 'PanelName',

            },


            {
                accessorKey: 'panelTotalLikes',
                header: 'Likes',
                enableEditing: false,

                Cell: ({ row, cell }) => {
                    // var val = (cell.getValue() != undefined) ? cell.getValue() : "";
                    // console.log(accessRights.games);
                    return (
                        // <Avatar {...stringAvatar(cell.getValue().toString())} />

                        <div><i style={{ color: "yellow" }} className='fa fa-star'></i> {cell.getValue().toString()}</div>


                    );
                },

            },
            {
                accessorKey: 'panelTotalPlays',
                header: 'Plays',
                enableEditing: false,
                size: 80,
                Cell: ({ row, cell }) => {
                    // var val = (cell.getValue() != undefined) ? cell.getValue() : "";
                    // console.log(accessRights.games);
                    return (
                        // <Avatar {...stringAvatar(cell.getValue().toString())} />

                        <div><i style={{ color: "var(--red)" }} className='fa fa-play'></i> {cell.getValue().toString()}</div>


                    );
                },
            },


            // {
            //     accessorKey: 'panelTotalPlaytime',
            //     header: 'Playtime',
            //     enableEditing: false,
            // },
            {
                accessorKey: 'panelDescription',
                header: 'PanelDescription',

                Cell: ({ row, cell }) => {
                    // var val = (cell.getValue() != undefined) ? cell.getValue() : "";
                    // console.log(accessRights.games);
                    return (
                        // <Avatar {...stringAvatar(cell.getValue().toString())} />
                        <Tooltip arrow placement="top" title={cell.getValue().toString()}>
                            <div className='text-truncate'>{cell.getValue().toString()}</div>
                        </Tooltip>

                    );
                },
                size: 220,

            },
            {
                accessorKey: 'panelId',
                header: 'PanelID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column

                size: 80,

            },
            {
                accessorKey: 'userId',
                header: 'UserID',
                enableEditing: false,

            },
            {
                accessorKey: 'panelUniqueName',
                header: 'Panel Unique',
                enableEditing: false,

            },

        ]
    );

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const columnDef = {
        'mrt-row-actions': {
            muiTableHeadCellProps: {
                align: 'center',
            },
            size: 120,
        },
    };



    useEffect(() => {

        PanelAPI.getAllPanels()
            .then((res) => {
                console.log(res);
                setTableData(res);
            });

    }, []);


    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <MaterialReactTable

                    displayColumnDefOptions={columnDef}
                    columns={columns}
                    data={tableData}
                    editingMode="modal" //default
                    enableColumnOrdering
                    enableEditing
                    onEditingRowSave={handleSaveRowEdits}
                    onEditingRowCancel={handleCancelRowEdits}
                    renderRowActions={({ row, table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            {
                                (props.userAccessControls.includes("PANEL_EDIT") || (props.currentUser.roleId == 8)) ?
                                    <Tooltip arrow placement="left" title="Edit">
                                        <IconButton onClick={() => table.setEditingRow(row)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                    : <></>
                            }

                            {
                                (props.userAccessControls.includes("PANEL_DELETE") || (props.currentUser.roleId == 8)) ?
                                    <Tooltip arrow placement="right" title="Delete">
                                        <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                    : <></>
                            }
                        </Box>
                    )}
                />

            </ThemeProvider>

        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelDatatable);