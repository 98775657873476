import { useState } from "react";

export function ImagePopup({ imageUrl }) {
    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <>
            <i className="fa fa-eye" style={{ cursor: "pointer", color: "lightseagreen" }} onClick={togglePopup}></i>
            {showPopup && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                    }}
                >
                    <img
                        src={imageUrl}
                        alt="Popup"
                        style={{
                            maxHeight: "80%",
                            maxWidth: "80%",
                            borderRadius: "8px",
                        }}
                    />
                    <button onClick={togglePopup} style={{ position: "absolute", top: 10, right: 10 }}>
                        Close
                    </button>
                </div>
            )}
        </>
    );
}
