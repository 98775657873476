import { API_BASE_URL } from "../../Constants"
import { api, apiHeader, axiosMultipart } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const GameAPI = {
    get: async function (id, cancel = false) {
        const response = await api.request({
            url: "/game/one/" + id,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },

    // let url = API_BASE_URL + "/game/page/" + props.genre + "/" + "0" + "/" + "8";
    getByGenre: async function (genre, st, en, cancel = false) {
        const response = await api.request({
            url: "/game/page/" + genre + "/" + st + "/" + en,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data.content
    },



    getByPanel: async function (panelId, st, en, cancel = false) {
        const response = await api.request({
            url: "/game/panelPage/" + panelId + "/" + null + "/" + st + "/" + en,
            method: "GET",
            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data.content
    },

    getAll: async function (cancel = false) {
        const response = await api.request({
            url: "/game/gameList",
            method: "GET",
            signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
        })

        return response.data;
    },

    delete: async function (gameId, cancel = false) {
        const response = await api.request({
            url: "/game/delete",
            method: "POST",

            params: {
                gameId: gameId,
            },

            headers: apiHeader,

            signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
        })

        //Temporary 
        //Because return 500 Error
        // window.location.reload();
        // console.log(response);
        return response;
    },


    upload: async function (formData, cancel = false) {
        const response = await axiosMultipart.request({
            url: "/game/upload",
            method: "POST",
            data: formData,
            withCredentials: true,


            _signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },
    setAvailability: async function (gameId, availability, cancel = false) {
        const response = await api.request({
            url: "/game/availability",
            method: "POST",

            params: {
                gameId: gameId,
                availability: availability
            },

            withCredentials: true,
            headers: apiHeader,



            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response.data
    },
    // create: async function (Game, cancel = false) {
    //     await api.request({
    //         url: `/games`,
    //         method: "POST",
    //         data: Game,
    //         signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
    //     })
    // },
}

// defining the cancel API object for GameAPI
const cancelApiObject = defineCancelApiObject(GameAPI)