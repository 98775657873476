import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Checkbox,
    Tooltip,
    FormGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    InputLabel,
    Select,
    Avatar,
} from '@mui/material';
import { Delete, Edit, Save } from '@mui/icons-material';
import { useSelector } from 'react-redux';

import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { AccessControlAPI } from '../../API/Endpoints/Backend/AccessControl/AccessControlAPI';
import { AuthAPI } from '../../API/Endpoints/Backend/AccessControl/AuthAPI';
import { signupAdmin } from '../../API/Configs/APIUtils';


//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {}),
    );

    const [accessControls, setAccessControls] = useState([]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [roleType, setRoleType] = useState("");

    const handleSubmit = () => {
        //put your validation logic here

        const req =
        {
            name: name, email: email, password: password, roleType: roleType
        };
        signupAdmin(req)
            .then(response => { console.log(response) });


        // AuthAPI.registerAdmin(
        //     JSON.stringify(req)
        // )
        //     .then(res => console.log(res));

        var temp = {
            name: name,
            email: email,
            password: password,
            roleType: roleType
        }

        onSubmit(temp);
        onClose();
    };

    useEffect(() => {
        AccessControlAPI.findAll()
            .then(res => {
                setAccessControls(res);
            });
    }, [])


    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Create New Account</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {/* {columns.map((column) => (
                            <TextField
                                key={column.accessorKey}
                                label={column.header}
                                name={column.accessorKey}
                                onChange={(e) =>
                                    setValues({ ...values, [e.target.name]: e.target.value })
                                }
                            />
                        ))} */}
                        <TextField
                            key="Name"
                            label="Name"
                            name="Name"
                            value={name}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                        />

                        <TextField
                            key="Email"
                            label="Email"
                            name="Email"
                            value={email}
                            onChange={(e) =>
                                setEmail(e.target.value)
                            }
                        />

                        <TextField
                            key="Password"
                            label="Password"
                            name="Password"
                            value={password}
                            onChange={(e) =>
                                setPassword(e.target.value)
                            }
                        />

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={roleType}
                                label="Role"
                                onChange={(e) =>
                                    setRoleType(e.target.value)
                                }
                            >
                                <MenuItem value={"ROLE_ADMIN"}>Role_Admin</MenuItem>
                                {/* <MenuItem value={"ROLE_ROOT"}>Role_Root</MenuItem> */}
                                {/* <MenuItem value={30}>Thirty</MenuItem> */}
                            </Select>
                        </FormControl>


                        {/* <Checkbox value={"Can Modify Games?"} /> */}

                        <FormControl component="fieldset">
                            <FormLabel component="legend">Access Rights: </FormLabel>


                            {/* <span> */}
                            {
                                accessControls.map(x => <FormControlLabel control={<Checkbox />} label={x.accessControlName} />)
                            }
                            {/* <FormControlLabel control={<Checkbox />} label="Games" />
                                <FormControlLabel control={<Checkbox />} label="Panels" />
                                <FormControlLabel control={<Checkbox />} label="Users" />
                                <FormControlLabel control={<Checkbox />} label="Moderation" />
                                <FormControlLabel control={<Checkbox />} label="Moderation" />
                                <FormControlLabel control={<Checkbox />} label="Moderation" />
                                <FormControlLabel control={<Checkbox />} label="Moderation" /> */}
                            {/* </span> */}

                        </FormControl>

                        {/* 
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Verification Access: </FormLabel>


                            <span>
                                <FormControlLabel control={<Checkbox />} label="Shadow Ban" />
                                <FormControlLabel control={<Checkbox />} label="Image Moderation" />



                            </span>

                        </FormControl> */}

                    </Stack>
                </form>
            </DialogContent>

            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="secondary" onClick={handleSubmit} variant="contained">
                    Create New Account
                </Button>
            </DialogActions>
        </Dialog>
    );
};
