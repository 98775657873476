import { API_BASE_URL } from "../../Constants"
import { api, apiHeader, axiosMultipart } from "../../Configs/axiosConfig"
import { defineCancelApiObject } from "../../Configs/axiosUtils"

export const PanelAPI = {

    getMyPanel: async function (cancel = false) {
        const response = await api.request({
            url: "/panelApi",
            method: "GET",

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data;
        // returning the Game returned by the API
        // console.log(response);
    },


    getPublicPanel: async function (panelId, cancel = false) {
        const response = await api.request({
            url: "/panelApi/publicPanel",
            method: "GET",

            params: {
                panelId: panelId
            },


            // headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data;
        // returning the Game returned by the API
        // console.log(response);
    },


    //Admin Side -- Authorizations is still to add
    getAllPanels: async function (cancel = false) {
        const response = await api.request({
            url: "/panelApi/findAll",
            method: "GET",

            // headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data;
    },

    findAllPanelGames: async function (cancel = false) {
        const response = await api.request({
            url: "/panelApi/panelGames",
            method: "GET",

            headers: apiHeader,

            // retrieving the signal value by using the property name
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        return response.data;
        // returning the Game returned by the API
        // console.log(response);
    },

    updatePanel: async function (formData, cancel = false) {
        const response = await axiosMultipart.request({
            url: "/panelApi/update",
            method: "POST",
            data: formData,
            // withCredentials: true,

            _signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },

    updatePanelByAdmin: async function (formData, cancel = false) {
        const response = await axiosMultipart.request({
            url: "/panelApi/updateByUser",
            method: "POST",
            data: formData,
            // withCredentials: true,

            _signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },



    createPanel: async function (formData, cancel = false) {
        const response = await axiosMultipart.request({
            url: "/panelApi/create",
            method: "POST",
            data: formData,
            withCredentials: true,

            _signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },

    deletePanel: async function (cancel = false) {
        const response = await api.request({
            url: "/panelApi/delete",
            method: "DELETE",
            withCredentials: true,
            headers: apiHeader,

            _signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },

    deletePanelById: async function (id, cancel = false) {
        const response = await api.request({
            url: "/panelApi/deleteByUser",
            method: "POST",
            withCredentials: true,
            // headers: apiHeader,

            params: {
                userId: id,
            },

            _signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        // returning the Game returned by the API
        return response;
    },






}

// defining the cancel API object for PanelAPI
const cancelApiObject = defineCancelApiObject(PanelAPI)